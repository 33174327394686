import React from "react"
import { Link } from "gatsby"
import "./Popup.css"

class Popup extends React.Component {
  render() {
    return (
      <div id="NewsDIV" className="popup">
        <div id="ContentHeaderDiv" className="popup-header"></div>
        <div id="ContentDiv" className="popup-body"></div>
        <div className="popup-footer">
          <Link class="closebtn" to={"javascript:closeWindow();"}>
            Close
          </Link>
        </div>
      </div>
    )
  }
}

export default Popup
